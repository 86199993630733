import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { OrderService } from 'src/app/services/order/order.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { ZoneService } from 'src/app/services/zone/zone.service';
import { TokenStorage } from 'src/app/auth/token.storage';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-editcustomer',
  templateUrl: './editcustomer.component.html',
  styleUrls: ['./editcustomer.component.scss']
})
export class EditcustomerComponent implements OnInit {
  customerForm: FormGroup;
  buList = ['Industrial'];
  allZones = [];
  disableSubmitButton = false;
  allBranches = [];

  isSuhana: boolean = false;
  associatedBranches = [];
  selZoneId: any;

  constructor(
    formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public router: Router,
    public customerService: CustomerService,
    public orderservice: OrderService,
    private dialog: MatDialog,
    private branchService: BranchService,
    private zoneService: ZoneService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private token: TokenStorage,
    private notifierService: NotifierService,
    private spinnerService: NgxSpinnerService
  ) {
    if (this.token.getTenant() == 'suhana-dist-test' || this.token.getTenant() == 'suhana-dist-prod') {
      this.isSuhana = true;
    }
    this.customerForm = formBuilder.group({
      'customerCode': [null, [
        Validators.required,
      ]],
      'customerName': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'customerType': [null, [
      ]],
      'houseNumber': [null, [
        Validators.required,
        ValidatorService.whitespaceValidator
      ]],
      'city': [null,
        [Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
        ]],
      'country': [null,
        [Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
        ]],
      'area': [null, [
        ValidatorService.whitespaceValidator
      ]],
      'zone': [null],
      'contactPerson': [null, [
        Validators.pattern("^[a-zA-Z]+$"),
        ValidatorService.whitespaceValidator
      ]],
      'phoneNo': [null, [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$')
      ]],
      'email': [null, [
        Validators.email]
      ],
      'pincode': [null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$')
      ]],
      'id': [null],
      'branches': [null, [
        Validators.required]],
      'unloadingcharges': [null, [
        Validators.maxLength(15),
        Validators.pattern('^[0-9]*$')
      ]]
    });

  }
  openSnackBar(message) {
    this.snackBar.open(message, 'ok', {
      duration: 2000,
      panelClass: ['extraClasses']
    });
  }

  ngOnInit() {
    this.customerForm.controls['customerCode'].disable();
    this.customerForm.controls['customerType'].disable();


    this.zoneService.getAllZones().subscribe(zones => {
      this.allZones = zones;
      console.log(zones);
    });

    this.branchService.branchesObservable.subscribe(allBranches => {
      this.allBranches = allBranches;

      this.allBranches.sort(function (a, b) {
        let branchArea1 = a.branchName.toLowerCase(), branchArea2 = b.branchName.toLowerCase()
        if (branchArea1 < branchArea2)
          return -1
        if (branchArea1 > branchArea2)
          return 1
        return 0
      })
    });


    this.allBranches.forEach(branch => {
      this.data.branches.forEach(availbranch => {
        if (branch.branchName + branch.branchCode == availbranch.branchName + availbranch.branchCode) {
          this.associatedBranches.push(branch);
        }
      });
    });

    if (this.data.zone)
      this.selZoneId = this.data.zone.zoneId
    console.log("customerInfo", this.data, this.selZoneId);
    let unloadingCharges = 0;
    if (this.data.customerConstraints && this.data.customerConstraints.unloadingCharges)
      unloadingCharges = this.data.customerConstraints.unloadingCharges
    this.customerForm.patchValue({
      customerName: this.data.customerName,
      customerCode: this.data.customerCode,
      customerType: this.data.customerType,
      email: this.data.email,
      id: this.data.id,
      phoneNo: this.data.phoneNo,
      area: this.data.address.area,
      city: this.data.address.city,
      pincode: this.data.address.pincode,
      country: this.data.address.country,
      zone: this.selZoneId,
      houseNumber: this.data.address.houseNumber,
      contactPerson: this.data.contactPerson,
      branches: this.data.branches,
      unloadingcharges: unloadingCharges
    })
  }

  editCustomer() {
    this.disableSubmitButton = true;
    this.spinnerService.show();
    let address = {}, customerConstraints = {};
    this.customerForm.value.customerCode = this.data.customerCode;
    this.customerForm.value.customerType = this.data.customerType;
    address = {
      houseNumber: this.customerForm.value.houseNumber,
      area: this.customerForm.value.area,
      city: this.customerForm.value.city,
      pincode: this.customerForm.value.pincode,
      country: this.customerForm.value.country,
    }

    if (this.data.customerConstraints) {
      customerConstraints = {
        customerConstraintsId: this.data.customerConstraints.customerConstraintsId,
        deliveryTimeEnd: this.data.customerConstraints.deliveryTimeEnd,
        deliveryTimeStart: this.data.customerConstraints.deliveryTimeStart,
        needsDedicatedVehicle: this.data.customerConstraints.needsDedicatedVehicle,
        unloadingCharges: Number(this.customerForm.value.unloadingcharges)
      }
    }
    else {
      customerConstraints = {
        customerConstraintsId: this.data.id,
        deliveryTimeEnd: null,
        deliveryTimeStart: null,
        needsDedicatedVehicle: null,
        unloadingCharges: Number(this.customerForm.value.unloadingcharges)
      }
    }
    delete this.customerForm.value.houseNumber;
    delete this.customerForm.value.area;
    delete this.customerForm.value.city;
    delete this.customerForm.value.pincode;
    delete this.customerForm.value.country;
    delete this.customerForm.value.unloadingcharges;

    this.allZones.forEach(zone => {
      if (zone.zoneId == this.customerForm.value.zone)
        this.customerForm.value.zone = zone
    })

    this.customerForm.value.address = address;
    let branches = [];
    console.log(this.associatedBranches)
    this.allBranches.forEach(branch => {
      this.associatedBranches.forEach(selbranch => {
        if (branch.branchId == selbranch.branchId) {
          branches.push(branch);
        }
      });
    });

    this.customerForm.value.branches = branches;
    this.customerForm.value.customerConstraints = customerConstraints;
    console.log("form value", this.customerForm.value);

    this.customerService.updateCustomer(this.customerForm.value).subscribe(customeradded => {
      console.log(customeradded);
      this.spinnerService.hide();
      this.notifierService.notify('success', 'Customer updated successfully.');
      this.dialog.getDialogById('editCustomerWindow').close(true);
    }, Error => {
      this.spinnerService.hide();
      this.notifierService.notify('error', 'Error in editing please try again later.');
      console.log(Error);
    });
  }
}
