import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSort } from '@angular/material';
import { TripService } from '../../../services/trip/trip.service';
import { MessagesService } from '../../../services/messages/messages.service';
import { DatePipe } from '@angular/common';
import { NgxDateRangePickerOptions } from '../../../../ngx-daterangepicker';
import { Router } from '@angular/router';
import { EdittripmodalComponent } from '../../schedulingandplanning/edittripmodal/edittripmodal.component';
import { TokenStorage } from 'src/app/auth/token.storage';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-scheduletriptable',
  templateUrl: './scheduletriptable.component.html',
  styleUrls: ['./scheduletriptable.component.scss']
})
export class ScheduletriptableComponent implements OnInit {

  columnsToDisplay = [
    'tripDetails',
    'origin',
    'zone',
    'orders',
    'transportDetails',
    'vehicleDetails',
    'capacityUtilization'
  ];
  tripsDataArray = new MatTableDataSource<tripListElement>();
  isZoneFiltered: boolean = false;
  zoneFilteredArray = [];
  zones = [];

  //if date is filtered assgin filtered data to new array for zone filtering
  isDateFiltered: boolean = false;
  dateFilteredArray = [];
  selectedValue: string = 'nozone'; // selected zone for filter date
  selectedDate: string; //for filter date
  newdupzone = [];
  trips: any;
  daterRangevalue: any;
  options: NgxDateRangePickerOptions;
  isFiltered: boolean = false;
  fromDate: any;
  toDate: any;
  scheduledCount: number = 0;
  page: number = 0;
  totalRecords: Number;
  totalRecordsWhenLoaded: Number;
  typeOfTrip: string;
  prevFromDate: any;
  prevTodate: any;
  prevZone: string = null;
  customerZones = [];
  isDialogClosed: boolean = false;
  isLoading: boolean = false;
  volUnit: string;
  filterString = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public tripService: TripService,
    public messagesService: MessagesService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    public router: Router,
    private token: TokenStorage,
    private notifierService : NotifierService
  ) {
    if (this.token.getTenant() == 'kimberly-dist-prod') {
      this.volUnit = 'CM';
    } else if (this.token.getTenant() == 'godrej-dist-prod') {
      this.volUnit = 'CCM';
    } else {
      this.volUnit = 'CF';
    }
  }

  ngOnInit() {
    let date;
    this.dialog.closeAll();
    this.typeOfTrip = "TRIP_STATE_APPROVED";
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    //30 is num of days
    date = new Date();
    this.fromDate = this.datePipe.transform(new Date(date.getTime() - (60 * 24 * 60 * 60 * 1000)), 'yyyy-MM-dd');
    this.findSelectedDateTrips();
    this.tripsDataArray.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'vehicleDetails': return item.vehicleType;
        case 'zone': return item.tripZone;
      }
    };
    this.tripsDataArray.sort = this.sort;
  }

  setDatePickerToCurrentDate() {
    this.options = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: null,
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'DD-MM-YYYY',
      startOfWeek: 1,
      outputType: 'object',
      locale: 'en',
      date: {
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        }
      }
    };
  }

  applyFilter(filterValue: string) {
    if (!filterValue.trim())
      this.tripsDataArray.filter = filterValue.trim().toLowerCase();

  }

  findSelectedDateTrips() {
    debugger
    console.log(this.fromDate);
    this.isLoading = true;
    this.tripsDataArray.data = [];
    this.getData(this.typeOfTrip, this.fromDate, this.toDate).subscribe(scheduledTripList => {
      this.isLoading = false;
      if (scheduledTripList.content.length === 0) {
        if (!this.isDialogClosed)
        this.notifierService.notify("success","Trips not found")
        this.tripsDataArray.data = [];
        this.totalRecords = 0;
        this.page = 0;
        this.paginator.pageIndex = 0;
      }
      else {
        console.log("Approved trips", scheduledTripList);
        this.trips = scheduledTripList.content;
        //this.tripsDataArray.data = scheduledTripList.content;
        this.findCustomerZonesForTrip(scheduledTripList.content);
        this.totalRecords = scheduledTripList.totalElements;
        this.totalRecordsWhenLoaded = scheduledTripList.totalElements
        console.log('total records', this.totalRecords);

        if (this.selectedValue) {
          this.isZoneFiltered = true;
        }
      }
    }, () => {
      this.isLoading = false;
    });
  }

  getData(type, from, to) {
    return this.tripService.getAllTripsByTypesDateRange(type, this.page, from, to, this.selectedValue);
  }

  pageChanged(event) {
    this.page = event.pageIndex;
    this.findSelectedDateTrips();
  }
  sortByZone() {
    console.log(this.selectedValue);
    this.isZoneFiltered = true;
    this.sortByDate();
  }


  sortByDate() {
    if (this.isDateFiltered && this.page > 0) {
      this.page = 0;
      this.paginator.pageIndex = 0;
    }
    console.log(this.daterRangevalue);
    if (this.selectedValue && !this.daterRangevalue) {
      this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.findSelectedDateTrips();
      this.prevFromDate = this.fromDate;
      this.prevTodate = this.toDate;
      this.prevZone = this.selectedValue;
    }

    else if (this.daterRangevalue) {
      this.fromDate = this.daterRangevalue.from.substring(6, 10) + '-' + this.daterRangevalue.from.substring(3, 5) + '-' + this.daterRangevalue.from.substring(0, 2);
      this.toDate = this.daterRangevalue.to.substring(6, 10) + '-' + this.daterRangevalue.to.substring(3, 5) + '-' + this.daterRangevalue.to.substring(0, 2);

      //call the function only if todate or fromdate or zone value changes 
      if (this.toDate != this.prevTodate || this.fromDate != this.prevFromDate || this.selectedValue != this.prevZone) {
        this.isDateFiltered = true;
        this.findSelectedDateTrips();
        this.prevFromDate = this.fromDate;
        this.prevTodate = this.toDate;
        this.prevZone = this.selectedValue;
      }
    }
  }



  clearAllFilter() {
    this.filterString = '';
    this.tripsDataArray.data = this.trips;
    let filterValue=''
    if (!filterValue.trim())
    this.tripsDataArray.filter = filterValue.trim().toLowerCase();
    this.totalRecords = this.totalRecordsWhenLoaded;
  }


  scheduleTripModal(data) {
    let dialogRef = this.dialog.open(EdittripmodalComponent, {
      width: '1200px',

      data: {
        data: data,
        component: 'scheduletriptable'
      }
    });
    dialogRef.updatePosition({
      top: '50px', right: '0px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){ 
        this.notifierService.notify("success","Trip Changes Saved Successfully")
      this.isDialogClosed = true;
      this.findSelectedDateTrips();
      }
    });
  }

  findCustomerZonesForTrip(tripsArray) {
    tripsArray.forEach(job => {
      this.customerZones = [];
      job.jobs.forEach(order => {
        let index = -1;
        if (order.customerInfo.zone) {
          index = this.customerZones.indexOf(order.customerInfo.zone.zoneName);
          if (index == -1) {
            this.customerZones.push(order.customerInfo.zone.zoneName);
          }
        }
      });
      job.customerZones = this.customerZones;
    });
    this.calculateBoxesForEachTrip(tripsArray);
  }

  calculateBoxesForEachTrip(tripsArray) {
    tripsArray.forEach(trip => {
      let totalboxes = 0;
      trip.jobs.forEach(job => {
        totalboxes = totalboxes + job.numOfContainers;
      });
      trip.totalBoxes = totalboxes;
    });
    this.tripsDataArray.data = tripsArray;
  }

  searchByTripName() {
    this.tripsDataArray.filter = this.filterString.trim().toLowerCase();
    console.log(this.tripsDataArray.filteredData.length)
    if (this.filterString.includes('Trip-') && this.tripsDataArray.filteredData.length <= 0 && JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length > 0) {
      this.isLoading = true;
      this.totalRecords = 0;
      this.tripService.getAllTripsByTripName(this.typeOfTrip, 0, null, null, this.selectedValue, this.filterString).subscribe(scheduledTripList => {
        this.isLoading = false;
        if (scheduledTripList.content.length === 0) {
          if (!this.isDialogClosed)
          this.notifierService.notify("success","Trips not found")
          this.tripsDataArray.data = [];
          this.totalRecords = 0;
          this.page = 0;
          this.paginator.pageIndex = 0;
        }
        else {
          console.log("Approved trips", scheduledTripList);
          //this.tripsDataArray.data = scheduledTripList.content;
          this.findCustomerZonesForTrip(scheduledTripList.content);
          this.totalRecords = scheduledTripList.totalElements;
          console.log('total records', this.totalRecords);

          if (this.selectedValue) {
            this.isZoneFiltered = true;
          }
        }
      }, () => {
        this.isLoading = false;
      });
    }
  }
}

export interface tripListElement {
  zone: String,
  tripName: string;
  tripPlannedStartDateTime: String;
  origin: string;
  destination: string;
  orders: string;
  transporter: string;
  vehicleDetails: string;
  driverDetails: string;
  capacityUtilization: string;
}